import { Button } from '@chakra-ui/button';
import { useColorMode } from '@chakra-ui/color-mode'
import { Image } from '@chakra-ui/image';
import { Stack, Circle, Flex, Box, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import React from 'react'

function Header() {

  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");

  return (
    <Stack>
      <Flex direction={isNotSmallerScreen ? "row" : "column"}
        spacing="200px" p={isNotSmallerScreen ? "32" : "0"}
        alignSelf="flex-start">
        <Box mt={isNotSmallerScreen ? "0" : 16} align='flex-start'>
          <Text fontSize="5xl" fontWeight="semibold">Hey There! my name is</Text>
          <Text fontSize="7xl" fontWeight="bold" bgGradient="linear(to-r, cyan.400, blue.500, purple.600)" bgClip='text' >Mayur Kukreja</Text>
          <Text color={isDark ? "gray.200" : "gray.500"}>Software Developer, Tech Enthusiast, Engineering Student, Currently exploring open-source and Flutter SDK</Text>
          <Button mt={8} colorScheme="blue" onClick={() =>
            window.open("https://www.linkedin.com/in/mayur-kukreja-797a921a0/")
          }>Hire Me</Button>

        </Box>
        <Image alignSelf="center" mt={isNotSmallerScreen ? "0" : "12"}
          mb={isNotSmallerScreen ? "0" : "12"} borderRadius='full'
          backgroundColor="transparent" boxShadow="lg"
          boxSize="300px" src='https://avatars.githubusercontent.com/u/67867070?v=4' />
      </Flex>

    </Stack>
  )
}

export default Header