import { IconButton } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Flex, VStack, Heading, Spacer } from "@chakra-ui/layout";
import { FaSun, FaMoon, FaInstagram, FaGithub, FaYoutube, FaLinkedin } from 'react-icons/fa'
import Header from "./components/Header";
import Profile from "./components/Profile";
import Social from "./components/Social";


function App() {

  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <VStack p={8}>
      <Flex w="100%">
        <Heading ml="8" size="md" fontweight="semibold" color="black.400">Portfolio-Website</Heading>
        <Spacer></Spacer>
      <IconButton ml={2} icon={<FaInstagram/>} isRound="true" onClick={() => window.open("https://www.instagram.com/mayurkukreja_")}></IconButton>
      <IconButton ml={2} icon={<FaGithub/>} isRound="true" onClick={() => window.open("https://github.com/mayurrkukreja")}></IconButton>
      <IconButton ml={2} icon={<FaYoutube/>} isRound="true" onClick={() => window.open("https://www.youtube.com/channel/UCwWOal_fbxUQdX-UvE0n_SA")}></IconButton>
      <IconButton ml={2} icon={<FaLinkedin/>} isRound="true" onClick={() => window.open("https://www.linkedin.com/in/mayur-kukreja-797a921a0/")}></IconButton>
      <IconButton ml={8} icon={isDark? <FaSun/> : <FaMoon />} isRound="true" onClick={toggleColorMode}></IconButton>
      </Flex>
      <Header></Header>
      <Social></Social>
      <Profile></Profile>

      
    </VStack>
  );
}

export default App;
